import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Person from '../components/person'
import Background from '../components/background'
import CompanyInformation from '../components/company_information'
import CompanyInformationItem from '../components/company_information_item'
import FindUs from '../components/find_us'

const AboutUsPage = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title={t('title')} language={i18n.language} />
          <Background title={t('about-us')} backgroundText={t('vision-desc')} backgroundTextPic="bg-section-background-about-us" big />
          <section className="parallax">
              <div className="about-us-title">{t('management').toUpperCase()}</div>
              <div className="person-container">
                  <Person name={t('name-tp')} role={t('role-tp')} bio={t('bio-tp')} image="mgmt_tp" />
                  <Person name={t('name-sg')} role={t('role-sg')} bio={t('bio-sg')} image="mgmt_sg" />
                  <Person name={t('name-vp')} role={t('role-vp')} bio={t('bio-vp')} image="mgmt_vp" />
                  <Person name={t('name-ft')} role={t('role-ft')} bio={t('bio-ft')} image="mgmt_ft" />
              </div>
          </section>
          <CompanyInformation title={t('company-information')}>
              <div className="company-information">
                  <div className="company-information-column">
                      <CompanyInformationItem name={t('info-full-name')} value={t('value-full-name')} />
                      <CompanyInformationItem name={t('info-operation')} value={t('value-operation')} />
                      <CompanyInformationItem name={t('info-established')} value={t('value-established')} />
                      <CompanyInformationItem name={t('info-employees')} value={t('value-employees')} />
                      <CompanyInformationItem name={t('info-address')} value={t('value-address')} />
                      <CompanyInformationItem name={t('info-activity')} value={t('value-activity')} />
                      <CompanyInformationItem name={t('info-capital')} value={t('value-capital')} />
                      <CompanyInformationItem name={t('info-ceo')} value={t('name-tp')} />
                      <CompanyInformationItem name={t('info-hu-vat-id')} value={t('value-hu-vat-id')} />
                      <CompanyInformationItem name={t('info-eu-vat-id')} value={t('value-eu-vat-id')} />
                      <CompanyInformationItem name={t('info-bank-name')} value={t('value-bank-name')} />
                      <CompanyInformationItem name={t('info-iban')} value={t('value-iban')} />
                      <CompanyInformationItem name={t('info-swift')} value={t('value-swift')} />
                      <CompanyInformationItem name={t('info-phone')} value={t('value-phone')} tel />
                      <CompanyInformationItem name={t('info-fax')} value={t('value-fax')} />
                  </div>
              </div>
          </CompanyInformation>
          <FindUs />
      </Layout>
  )
}

export default AboutUsPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "about-us", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
