import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

const FindUs = () => {
  const { t } = useI18next()

  return (
      <div className="page-section">
          <div className="page-section-title">
              <p>{t('find-us').toUpperCase()}</p>
          </div>
          <div className="map">
              <iframe title="map" width="600" height="450" frameBorder="0" allowFullScreen src="https://maps.google.hu/maps?f=q&amp;source=s_q&amp;hl=hu&amp;geocode=&amp;q=AITIA+International+Informatikai+Kft.,+Budapest,+Czetz+J%C3%A1nos+utca&amp;aq=0&amp;oq=AITIA+&amp;sll=47.581339,19.054188&amp;sspn=0.005059,0.011362&amp;ie=UTF8&amp;hq=AITIA+International+Informatikai+Kft.,&amp;hnear=Budapest,+III.+ker%C3%BClet,+Czetz+J%C3%A1nos+utca&amp;t=m&amp;ll=47.581447,19.054585&amp;spn=0.006948,0.013733&amp;z=16&amp;output=embed" />
          </div>
      </div>
  )
}

export default FindUs
