import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Person = ({
  name, role, bio, image,
}) => {
  const data = useStaticQuery(graphql`
    query AboutUsImages {
        images: allFile(filter: { relativeDirectory: { eq: "about-us" } } ) {
            nodes {
                id
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
  `)

  const picture = data.images.nodes.find((n) => n.childImageSharp.fluid.src.includes(image))

  return (
      <div className="row">
          <div className="person_card">
              <figure className="person_card_shape">
                  <Img fluid={picture.childImageSharp.fluid} alt={name} className="person_card_image" />
              </figure>
              <div className="person_card_bio">
                  <h3>{name}</h3>
                  <h5>{role}</h5>
                  <p>{bio}</p>
              </div>
          </div>
      </div>
  )
}

Person.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}

export default Person
